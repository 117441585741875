import React from "react"
import { StringParam, useQueryParam } from "use-query-params"
import { addDays } from "date-fns"

import useJudgeMeReviews from "./useJudgeMeReviews"
import { BicycleProductPageProps } from "../views/BicycleProductPage"
import { ShopifyProductVariantFieldsType } from "../shopify/graphql/fragments"

interface MetaType {
  name: string
  content: string
}

export interface UseProductSEODataProps {
  productHandle?: string
  bikeData: BicycleProductPageProps
  pricing: {
    data: ShopifyProductVariantFieldsType[]
    isLoading: boolean
  }
}

export interface UseProductSEODataReturnType {
  json: {
    [key: string]: any
  }
}

export const useProductSEOData = ({
  productHandle,
  bikeData,
  pricing: { data: pricingData, isLoading },
}: UseProductSEODataProps) => {
  const [productJson, setProductJson] = React.useState<{
    [key: string]: any
  }>({})

  const reviews = useJudgeMeReviews(productHandle)
  const [variantId, setVariantId] = useQueryParam("variant", StringParam)

  const setProductSEOData = () => {
    const url = `https://www.quran411.website/products/${bikeData.data.bike.internalTitle}?variant=${variantId}`
    setProductJson({
      "@context": "https://schema.org",
      "@id": url,
      "@type": "Product",
      sku: variantId,
      mpn: variantId,
      brand: {
        "@type": "Brand",
        name: "sixthreezero",
      },
      description: bikeData.data.bike.seoDescription,
      url,
      ...(reviews.goodReview
        ? {
            review: {
              "@type": "Review",
              reviewRating: {
                "@type": "Rating",
                ratingValue: reviews.goodReview.rating,
                bestRating: "5",
              },
              author: {
                "@type": "Person",
                name: reviews.goodReview.reviewer.name,
              },
            },
          }
        : {}),
      ...(reviews.numReviews > 0
        ? {
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: `${reviews.avgRating}`,
              reviewCount: reviews.numReviews,
              bestRating: 5,
            },
          }
        : {}),
      name: bikeData.data.bike.seoTitle,
      image: [
        bikeData.data.bike.variants.find((variant) => variant.sku === variantId)
          ?.productListingImage?.src,
      ],
      offers: bikeData.data.bike.variants.map((variant) => {
        const pricing = pricingData.find(
          (priceData) => priceData?.id === variant.shopifyId
        )
        return {
          "@type": "Offer",
          availability: "https://schema.org/InStock",
          price: `${pricing?.priceV2.amount}`,
          priceCurrency: pricing?.priceV2.currencyCode,
          priceValidUntil: addDays(new Date(), 1).toDateString(),
          itemCondition: "https://schema.org/NewCondition",
          url,
          image: variant.productListingImage?.src,
          mpn: variant.sku,
          sku: variant.sku,
          seller: {
            "@type": "Organization",
            name: "Sixthreezero",
          },
        }
      }),
    })
  }

  React.useEffect(() => {
    if (!reviews.isLoading && !isLoading && pricingData.length > 0) {
      setProductSEOData()
    }
  }, [isLoading, pricingData, reviews.isLoading])

  return {
    json: [productJson],
  }
}
